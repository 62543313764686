import React, { useState } from "react";
import { Card, Row, Col, Container, Button, Modal } from 'react-bootstrap';
import { FaCheck, FaArrowDown } from 'react-icons/fa';

import Image from "../../../components/Image";
import Page from "../../../components/Page";
import BannerParallax from "../../../components/BannerParallax";

import imgBanner from "../../../images/banner/banner-8-fast-track.jpg";
import imgPreparation from "../../../images/online-program/fast-track-preparation.jpg";

import SectionFAQ from "../parts/SectionFAQ";
import { Link } from "gatsby";

const DATA_EN = {
  title: "FastTrack",
  subtitle: "Produce & Launch Your Single",
  youtube_code: "JDO1kWLtAHc",
  desc: [
    "Do you dream of composing your own single to then publish it online? If you love to sing or play any music instruments, then this program is for you. Just how do we turn your dreams into reality?",
    "First, we’ll help you turn your basic composition idea into a full song. We’ll then produce the accompaniment music or backing track in the genre of your choice. Afterwards, you’ll be guided every step of the way in the recording process. Once that’s done, we’ll have it polished to perfection. And finally, your song is ready to upload to any streaming platforms, such as SPOTIFY.",
    "We will work closely with you through multiple sessions of ZOOM meeting, so you can be sure that you’re fully involved in the development of the project. The end result will be exactly like what you have in mind (if not better).",
    "Are you ready let the world outside hear your unique musical talents? Get in touch with us today."
  ],
  preparation: {
    title_section: "WHAT TO PREPARE",
    steps: [
      {
        title: "Pick your main instrument \n",
        desc: "(vocal or any instruments of your choice)"
      },
      {
        title: "Have a rough idea of some elements of your composition \n",
        desc: "(i.e. some lyrics or some melody)"
      },
      {
        title: "Pick a reference \n",
        desc: "(i.e. a song, an artist, a band, or a composer)"
      }
    ]
  },
  process: {
    title_section: "THE PROCESS",
    table_title: ["You", "The Process", "We"],
    steps: [
      { you: true, desc: "30-minute ZOOM meeting", we: true },
      { you: true, desc: "Discuss initial composition", we: true },
      { you: false, desc: "Turn it into final composition", we: true },
      { you: true, desc: "30-minute ZOOM meeting", we: true },
      { you: false, desc: "Create backing track / accompaniment", we: true },
      { you: true, desc: "30-minute ZOOM meeting", we: true },
      { you: true, desc: "Record vocal or main instrument", we: false },
      { you: true, desc: "30-minute ZOOM meeting", we: true },
      { you: false, desc: "Mixing", we: true },
      { you: false, desc: "Mastering", we: true },
      { you: false, desc: "Create Artwork", we: true },
      { you: false, desc: "Publish to streaming platform", we: true },
      { you: true, desc: "Show it to the world!", we: false },
    ]
  },
  pricing: {
    title_section: "PRICING",
    package: [
      {
        id: "package_01",
        title: "BASIC",
        price_usd: "USD 697",
        price_idr: "IDR 9,700,000",
        sessions: "4-6 sessions",
        backing_track: ["Band (piano, guitar, bass, drum)"],
        mixing: "Basic",
        mastering: "Basic",
        sample_link: "http://www.music-republic.com/audio/angan-basic.mp3"
      },
      {
        id: "package_02",
        title: "SEMI PROFESSIONAL",
        price_usd: "USD 857",
        price_idr: "IDR 11,700,000",
        sessions: "4-6 sessions",
        backing_track: ["Band (piano, guitar, bass, drum)"],
        mixing: "Professional",
        mastering: "Professional",
        sample_link: "http://www.music-republic.com/audio/angan-semipro.mp3"
      },
      {
        id: "package_03",
        title: "PROFESSIONAL",
        price_usd: "USD 997",
        price_idr: "IDR 13,700,000 ",
        sessions: "4-6 sessions",
        backing_track: ["Band (piano, guitar, bass, drum)", "Orchestra (strings, brass, etc)"],
        mixing: "Professional",
        mastering: "Professional",
        sample_link: "http://www.music-republic.com/audio/angan-pro.mp3"
      }
    ],
    registration_link: "https://forms.gle/GvwhkyUXrYTAwtbv6"
  },
  faq: [
    {
      id: "0",
      question: "If I only write a fraction of a song, say the chorus part only, can you turn it into a whole song?",
      answer: "Yes, we can compose the verse and the bridge for you, and perhaps add intro and outro as necessary. We will discuss the detail with you through ZOOM meeting."
    },
    {
      id: "1",
      question: "If I only have lyrics/poem, can you turn it into a song?",
      answer: "Yes."
    },
    {
      id: "2",
      question: "How do I record my voice or my instrument?",
      answer: "If you don’t have any recording equipments, you have 2 options. The first, and most inexpensive, option is to record your main instrument (be it your voice or other instrument) with your cellphone. The second option is to search for a nearby recording studio, book the studio for a one or two hours, and record there. This second option is more costly, but you will get a much better sound quality. Please note that the additional cost for renting a recording studio is not included in our service."
    },
  ]
}
const DATA_ID = {
  title: "FastTrack",
  subtitle: "Produksi & Rilis Lagumu di Spotify",
  youtube_code: "-3msI4ykw6M",
  desc: [
    "Ingin menulis dan memproduksi lagu, lalu merilisnya di SPOTIFY? Jika Anda bisa menyanyi atau memainkan sebuah alat musik, dengan bantuan kami, Anda bisa memproduksi lagu/single Anda dengan sangat mudah.",
    "Tim guru musik kami akan membantu menulis lagu untuk Anda atau memoles lagu karangan Anda yang sudah ada. Setelah itu, kami akan memproduksi iringannya, memandu proses rekaman vokal atau alat musik pilihan Anda, menyatukan & memproses semuanya, sampai merilisnya ke SPOTIFY.",
    "Untuk memastikan Anda terlibat sepenuhnya dalam proses ini, Anda akan berkomunikasi dengan kami melalui beberapa sesi pertemuan ZOOM selama projek berjalan.",
    "Siap melansir karya musik Anda?"
  ],
  preparation: {
    title_section: "APA YANG PERLU DIPERSIAPKAN?",
    steps: [
      {
        title: "Tentukan alat musik utama Anda \n",
        desc: "(misalnya vokal atau alat musik instrumental lainnya)"
      },
      {
        title: "Pastikan Anda sudah ada sedikit ide \n",
        desc: "(misalnya sedikit lirik atau melodi)"
      },
      {
        title: "Pilihlah sebuah referensi sebagai titik acuan \n",
        desc: "(misalnya lagu, artis, band, atau composer)"
      }
    ]
  },
  process: {
    title_section: "BAGAIMANA PROSESNYA?",
    table_title: ["Anda", "Proses", "Kami"],
    steps: [
      { you: true, desc: "Diskusi 1 (ZOOM 30 menit)", we: true },
      { you: true, desc: "Diskusi awal", we: true },
      { you: false, desc: "Mengembangkan ide lagu sampai jadi", we: true },
      { you: true, desc: "Diskusi 2 (ZOOM 30 menit)", we: true },
      { you: false, desc: "Membuat iringan lagu", we: true },
      { you: true, desc: "Diskusi 3 (ZOOM 30 menit)", we: true },
      { you: true, desc: "Merekam suara atau alat musik pilihan Anda", we: false },
      { you: true, desc: "Diskusi 4 (ZOOM 30 menit)", we: true },
      { you: false, desc: "Mixing", we: true },
      { you: false, desc: "Mastering", we: true },
      { you: false, desc: "Membuat artwork", we: true },
      { you: false, desc: "Merilis lagu ke SPOTIFY", we: true },
      { you: true, desc: "Sebarkan berita baik di media sosial Anda!", we: false },
    ]
  },
  pricing: {
    title_section: "HARGA PAKET",
    package: [
      {
        id: "package_01",
        title: "BASIC",
        price_idr: "IDR 9,700,000",
        sessions: "4-6 kali",
        backing_track: ["Band (piano, guitar, bass, drum)"],
        mixing: "Basic",
        mastering: "Basic",
        sample_link: "http://www.music-republic.com/audio/angan-basic.mp3"
      },
      {
        id: "package_02",
        title: "SEMI PROFESSIONAL",
        price_idr: "IDR 11,700,000",
        sessions: "4-6 kali",
        backing_track: ["Band (piano, guitar, bass, drum)"],
        mixing: "Professional",
        mastering: "Professional",
        sample_link: "http://www.music-republic.com/audio/angan-semipro.mp3"
      },
      {
        id: "package_03",
        title: "PROFESSIONAL",
        price_idr: "IDR 13,700,000",
        sessions: "4-6 kali",
        backing_track: ["Band (piano, guitar, bass, drum)", "Orkestra (strings, brass, dll)"],
        mixing: "Professional",
        mastering: "Professional",
        sample_link: "http://www.music-republic.com/audio/angan-pro.mp3"
      }
    ],
    registration_link: "https://forms.gle/MYKdiCfCd9qv4Rr29"
  },
  faq: [
    {
      id: "0",
      question: "Apabila saya hanya ada sedikit ide bagian reff, apakah bisa dibantu menulis bagian-bagian lainnya? ",
      answer: "Bisa, kami akan membantu mengarang bagian verse & bridge, dan menambahkan intro & outro bila diperlukan. Detilnya akan kami diskusikan dengan Anda dalam sesi ZOOM. "
    },
    {
      id: "1",
      question: "Apabila saya hanya memiliki lirik, apakah bisa dijadikan sebuah lagu? ",
      answer: "Bisa."
    },
    {
      id: "2",
      question: "Bagaimana cara saya merekam suara atau alat musik saya? How do I record my voice or my instrument?",
      answer: "Bila Anda tidak memiliki peralatan rekaman, ada 2 opsi. Opsi 1, yang paling praktis dan ekonomis, rekam dengan HP atau gadget Anda. Opsi 2, menyewa studio rekaman terdekat 1-2 jam untuk merekam di sana. Opsi kedua ini akan menghasilkan rekaman dengan kualitas yang jauh lebih bagus dan jernih. Catatan: biaya sewa studio rekaman tidak termasuk dalam jasa kami.  "
    },
  ]
}

export default () => {
  const pageTitle = "Online Program"

  var lang = ""
  if (typeof window !== "undefined") {
    const queryURL = new URLSearchParams(window.location.search);
    lang = queryURL.get('lang')
  }

  const [isLang, setLanguage] = useState(lang);
  var DATA_SET = isLang === "id" ? DATA_ID : DATA_EN

  const handleLanguage = (val) => {
    setLanguage(val)
    setModalShow(false)
  };

  const [modalShow, setModalShow] = useState(!lang);
  function LanguageChooserModal(props) {
    return (
      <Modal
        {...props}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        size="lg"
        backdrop="static"
        backdropClassName="fast-track-modal-backdrop"
      >
        <Modal.Header className="justify-content-center">
          <h2 className="h1 h1-responsive font-weight-bold">
            Language Option
          </h2>
        </Modal.Header>
        <Modal.Body className="py-5">
          <Container>
            <Row>
              <Col className="text-right"><Button block size="lg" onClick={() => handleLanguage("id")}>Indonesian <span role="img" aria-label="id">🇮🇩</span></Button></Col>
              <Col className="text-left"><Button block size="lg" onClick={() => handleLanguage("en")}>English <span role="img" aria-label="en">🇬🇧</span></Button></Col>
            </Row>
          </Container>
        </Modal.Body>
      </Modal >
    );
  }

  return (
    <Page title={pageTitle} className="page-fast-track">
      <LanguageChooserModal
        show={modalShow}
        onHide={() => setModalShow(false)}
      />
      <BannerParallax title={pageTitle} background={imgBanner} />
      {/* Title */}
      <section className="section_padding" >
        <div className="container">
          {/* page title */}
          <div className="row justify-content-center align-items-center">
            <div className="col-12">
              <div className="section_title text-center">
                <h1>{DATA_SET.title}</h1>
                <span>{DATA_SET.subtitle}</span>
              </div>
            </div>
          </div>

          {/* video */}
          <div className="row mb-4">
            <div className="col-md-10 mx-auto">
              <div className="mb-2">
                <div className="embed-responsive embed-responsive-16by9">
                  <iframe title="Music Republicain Online Lesson" className="embed-responsive-item" src={"https://www.youtube.com/embed/" + DATA_SET.youtube_code} frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                </div>
              </div>
              <section>
              </section>
            </div>
          </div>

          {/* description */}
          <div className="row mb-4">
            <div className="col-md-10 mx-auto">
              {DATA_SET.desc.map(item => (
                <p className="lead">{item}</p>
              ))}
            </div>
          </div>
        </div>
      </section >

      {/* What To Prepare */}
      < section className="section_padding section_bg" >
        <Container>
          <Row>
            <Col>
              {/* <!-- Section heading --> */}
              <h2 className="h1 h1-responsive font-weight-bold mb-5 text-center">{DATA_SET.preparation.title_section}</h2>
            </Col>
          </Row>
          <div className="row mb-4">
            <div className="col-md-6">
              <Image
                className="w-100"
                src={imgPreparation}
                alt="concert hall music republicain"
              />
            </div>
            <div className="col-md-6 py-md-4">
              <section>
                {
                  DATA_SET.preparation.steps.map((item, index) => (
                    <div className="text-center" key="index">
                      <p className="lead">
                        <span className="font-weight-bold d-block">{item.title}</span>
                        <span>{item.desc}</span>
                        <br />
                      </p>
                      {DATA_SET.preparation.steps.length > (index + 1) && <FaArrowDown className="mb-2" />}
                    </div>
                  ))
                }
              </section>
            </div>
          </div>
        </Container>
      </section >

      {/* Process */}
      < section className="section_padding" >
        <Container>
          <Row>
            <Col>
              {/* <!-- Section heading --> */}
              <h2 className="h1 h1-responsive font-weight-bold mb-5 text-center">{DATA_SET.process.title_section}</h2>
            </Col>
          </Row>
          <Row className="no-gutters">
            <Card className="col-md-8 mx-auto shadow rounded">
              <div className="table-responsive">
                <table className="table table-borderless table-striped table-hover text-center">
                  <thead>
                    <tr>
                      {DATA_SET.process.table_title.map(item => (
                        <th scope="col"><h3>{item}</h3></th>
                      ))}
                    </tr>
                  </thead>
                  <tbody>
                    {DATA_SET.process.steps.map(item => (
                      <tr className="lead">
                        <td>{item.you && <FaCheck />}</td>
                        <td>{item.desc}</td>
                        <td>{item.we && <FaCheck />}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </Card>
          </Row>
        </Container>
      </section >

      {/* PRICING */}
      < section className="section_padding section_bg" >
        <Container>
          <Row>
            <Col>
              {/* <!-- Section heading --> */}
              <h2 className="h1 h1-responsive font-weight-bold mb-5 text-center">{DATA_SET.pricing.title_section}</h2>
            </Col>
          </Row>

          {/* price list */}
          <Row>
            <div className="card-deck col-md-12 mx-auto">
              {DATA_SET.pricing.package.map(item => (
                <Card className="card mb-4 shadow-sm">
                  <Card.Header className="text-center">
                    <h3 className="my-0 font-weight-normal">{item.title}</h3>
                  </Card.Header>
                  <Card.Body className="card-body">
                    <h1 className="card-title pricing-card-title text-center">
                      {item.price_usd ?
                        <>
                          {item.price_usd} <br />
                          <small>({item.price_idr})</small>
                        </>
                        : item.price_idr
                      }
                    </h1>
                    <ul className="unordered-list mt-3 mb-4 lead">
                      <li>
                        {isLang === "id" ?
                          <>ZOOM 30 menit</> :
                          <>30-minute ZOOM Meeting</>
                        }
                        <small> ({item.sessions})</small>
                      </li>
                      <li>
                        {isLang === "id" ?
                          <>Iringan <small>(menggunakan audio sampling / virtual instruments)</small> : </> :
                          <>Backing Track <small>(using good quality audio sampling / virtual instruments)</small> : </>
                        }
                        <ul className="list-unstyled">
                          <li>
                            - Band <small>(piano, guitar, bass, drum)</small>
                          </li>
                          {item.title === "PROFESSIONAL" ?
                            <li>
                              {isLang === "id" ?
                                <>- Orkestra <small>(strings, brass, dll)</small></> :
                                <>- Orchestra <small>(strings, brass, etc)</small></>
                              }
                            </li> :
                            <></>
                          }
                        </ul>
                      </li>
                      <li>{item.mixing} Mixing</li>
                      <li>{item.mastering} Mastering</li>
                    </ul>
                    {/* {
                      item.id === "package_02" &&
                      <figure>
                        <figcaption className="lead"><small className="font-weight-bold">Audio sample :</small></figcaption>
                        <audio controls src="http://www.music-republic.com/audio/angan-tarra.mp3">
                          Your browser does not support the <code>audio</code> element.
                        </audio>
                      </figure>
                    } */}
                  </Card.Body>
                  <Card.Footer>
                    <figure className="background-primary">
                      <figcaption className="lead"><small className="font-weight-bold">Audio sample :</small></figcaption>
                      <audio className="w-100" controls src={item.sample_link}>
                        Your browser does not support the <code>audio</code> element.
                      </audio>
                    </figure>
                    <a href={DATA_SET.pricing.registration_link} target="_blank" rel="noreferrer" className="btn btn-lg btn-block btn-primary btn_1">
                      {isLang === "id" ? "Daftar" : "Get Started"}
                    </a>
                  </Card.Footer>
                </Card>
              ))}
            </div>
          </Row>

          <Row>
            <Col className="col-md-12 mx-auto">
              {/* <!-- Section description --> */}
              {isLang === "id" ?
                <div className="px-md-3 pt-3">
                  <h4>PENAMBAHAN LAIN</h4>
                  <p className="dark-grey-text w-responsive">
                    Apabila Anda ada permintaan khusus, silakan <Link to="/contact-us/">hubungi kami</Link> untuk penawaran harga. <br />
                    Contoh permintaan khusus:
                    <ul className="unordered-list">
                      <li>Anda ingin membuat rekaman COVER, bukan mengarang lagu</li>
                      <li>Anda ingin menambahkan backing vokal beberapa suara</li>
                      <li>Anda ingin menambahkan beberapa alat musik yang direkam live</li>
                      <li>Anda ingin iringan dengan format spesifik (seperti trio, quartet, atau quintet)</li>
                      <li>dan lain lain</li>
                    </ul>
                  </p>
                </div>
                :
                <div className="px-md-3 pt-3">
                  <h4>ADD-ONS / ADDITIONAL REQUESTS</h4>
                  <p className="dark-grey-text w-responsive">
                    If you have specific requests, feel free to <Link to="/contact-us/">contact us</Link>. <br />
                    Some ideas for add-ons:
                    <ul className="unordered-list">
                      <li>You want to do cover instead of original composition</li>
                      <li>You want several layers of backing vocals</li>
                      <li>You want some live instruments (recorded live)</li>
                      <li>You don’t want band intruments, you prefer full orchestration</li>
                      <li>and any other requests</li>
                    </ul>
                  </p>
                </div>
              }
            </Col>
          </Row>
        </Container>
      </section >

      {/* terms & condition 
      < section className="section_padding" >
        <div className="container">
          <Row>
            <Col>
              <h2 className="h1-responsive font-weight-bold mb-5 text-center">TERMS &amp; CONDITIONS</h2>
            </Col>
          </Row>
          <Row>
            <Col className="col-md-10 mx-auto">
              <p className="dark-grey-text w-responsive mx-auto">
                <ul className="unordered-list">
                  <li>We include 4-6 ZOOM sessions @ 30 minutes. For extra ZOOM sessions, we charge an additional fee of $30 per 30 minutes.</li>
                  <li>The duration of the project is 4-8 weeks. After 8 weeks, we are no longer responsible for the project. </li>
                  <li>We do not provide refund under any circumstances</li>
                  <li>If your composition is 100% when you reach us, then the copyright is yours. If your composition is 50% done, and we do the other 50%, then the copyright is 50-50 (we are the co-author). If your start with a rough idea, and we write the composition for you, then the copyright is ours.</li>
                  <li>Regardless, the artist is and will always be YOU. </li>
                </ul>
              </p>
            </Col>
          </Row>
        </div>
      </section >
      */}

      {/* FAQ */}
      < section className="section_padding padding_bottom" >
        <SectionFAQ data={DATA_SET.faq} />
      </section >
    </Page >
  )
}
